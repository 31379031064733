import { render, staticRenderFns } from "./FaqSection.vue?vue&type=template&id=178cc745&scoped=true&"
import script from "./FaqSection.vue?vue&type=script&lang=ts&"
export * from "./FaqSection.vue?vue&type=script&lang=ts&"
import style0 from "./FaqSection.vue?vue&type=style&index=0&id=178cc745&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "178cc745",
  null
  
)

export default component.exports